var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"devices",attrs:{"align":"start","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.displayDevices,"loading":_vm.pageLoading,"server-items-length":_vm.totalCount,"footer-props":_vm.footerProps,"options":{
          page: _vm.pageIndex,
          itemsPerPage: _vm.pageSize,
          sortBy: _vm.sort.sortBy,
          sortDesc: _vm.sort.sortDesc,
        },"multi-sort":""},on:{"update:options":function($event){return _vm.paginate($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("device_page.title"))+" "),_c('TableSettings',{staticClass:"ml-5",attrs:{"pIsVisible":_vm.tableInited && !_vm.isDefaultSettings,"pSaveButtonTooltip":_vm.$localizationService.localize('device_page.settings_tooltip.save'),"pDefaultButtonTooltip":_vm.$localizationService.localize('device_page.settings_tooltip.apply_default')},on:{"onSaveSettings":function($event){return _vm.saveSettings()},"onApplyDefaultSettings":function($event){return _vm.applyDefaultSettings()}}})],1),_c('v-spacer'),_c('v-text-field',{staticClass:"search-field",attrs:{"label":_vm.$localizationService.localize('device_page.search'),"hide-details":""},on:{"input":function($event){return _vm.onInputSearchString()}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEditDeviceDialog()}}},[_vm._v(" "+_vm._s(_vm.$localizationService.localize("device_page.new_btn"))+" ")]),_c('EditDeviceDialog',{ref:"devicesDialog"}),_c('ConfirmationDialog',{ref:"deleteDialog"})],1)]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('span',{key:header.value,class:{
            'not-default-header-sort': _vm.isNotDefaultSortItems.includes(header.value)
            }},[_vm._v(" "+_vm._s(header.text)+" ")])]},proxy:true}}),{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('MessageButton',{staticClass:"ml-1",attrs:{"target":_vm.MessagingTargets.device(item.id)}})],1)]}},{key:"item.number",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.number)+" ")]}},{key:"item.createdAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.momentDateAndTime(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openEditDeviceDialog(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.openDeleteDeviceDialog(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }